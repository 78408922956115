
import React, { useEffect, useState } from 'react'
import { AnimatePresence, motion } from "framer-motion";
import Link from 'next/link';
import Image from 'next/image';
import { isMobileContext } from '../../contexts/isMobileContext';
import { useContext } from 'react';
import { BASE_URL } from '../../utils/FetchData';
import { useRouter } from 'next/router';
import { nextRouteContext } from '../../contexts/nextRouteContext';
import { useStatStyles } from '@chakra-ui/react';
import Magnitizer from '../Animations/Magnitizer';
import FadeIn from '../Animations/FadeIn';
import ArrowsSpliting from '../Animations/ArrowsSpliting';
import Button from '../common/Button';
import { useTranslation } from 'next-i18next';
const UpdateCard = ({ isHover, index, setIsHover, activeIndex, data }) => {
    const { isMobile } = useContext(isMobileContext);
    const { setNextRoute } = useContext(nextRouteContext)
    const router = useRouter()
    const [date, setDate] = useState()
    const { t } = useTranslation("common")
    useEffect(() => {
        const newDate = new Date(data?.attributes?.date)
        const month = newDate.toLocaleString(router.locale, { month: 'long' })
        if (router.locale == "ar") {
            setDate(`${data?.attributes?.date?.split("-")[2]} ${month} ${newDate.getFullYear()} . `)
        } else {
            setDate(`${month.slice(0, 3)} ${data?.attributes?.date?.split("-")[2]}, ${newDate.getFullYear()} . `)
        }
    }, [data, router.locale])
    const container = {
        visible: {
            transition: {
                staggerChildren: 0.2,
                delayChildren: 0,
                ease: "easeInOut",
            }
        }
    }
    const childs = {
        hidden: { opacity: 0, y: 30 },
        visible: {
            opacity: 1, y: 0, transition: {
                duration: .75
            }
        }
    }
    return (
        <motion.div className={`update-card ${isHover == index ? 'hoverd' : ""}`}
            onHoverStart={() => { setIsHover(index) }}
            onHoverEnd={() => { setIsHover(-1) }}
        >
            <motion.div className='img-conatiner'>
                <div className="overlay"></div>
                <>
                    <span className='tag bold'>{data?.attributes?.article_tab?.data?.attributes?.name}</span>
                    {isHover != index && <span className='tag tag-title bold'>{data?.attributes?.title}</span>}
                    {isHover == index &&
                        <>
                            <motion.span
                                initial={{ opacity: 0, y: 40 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ delay: 0, duration: .15 }}
                                onClick={() => {
                                    setNextRoute(data?.attributes?.article_tab?.data?.attributes?.name)
                                }} className='_eleY circle bold'>
                                <Button
                                    text={t("common.read_more")}
                                    link={`/media-center/${data?.attributes?.slug}`}
                                />
                            </motion.span>
                            <span className='date'>{date}{data?.attributes?.minute_read} {t("common.minutes-read")}</span>
                        </>
                    }
                </>
                {data &&
                    <Image
                        src={BASE_URL + (isMobile ? data?.attributes?.home_card_img_mobile?.data?.attributes?.url : data?.attributes?.home_card_img_desktop?.data?.attributes?.url)}
                        alt={data?.attributes?.home_card_img_desktop?.data?.attributes?.name}
                        layout="fill"
                        objectFit="cover"
                    />
                }
            </motion.div>
            <AnimatePresence>
                {isHover == index &&
                    <motion.div className='slide-data'
                        initial={"hidden"}
                        exit={"hidden"}
                        whileInView={"visible"}
                        variants={container}
                    >
                        <motion.span variants={childs} className='title'>{data?.attributes?.title}</motion.span>
                        <motion.p variants={childs}>{data?.attributes?.cards_description}</motion.p>
                    </motion.div>}
            </AnimatePresence>
        </motion.div>
    )
}

export default UpdateCard