
import React, { useContext } from 'react'
import { Rings } from 'react-loader-spinner';
import { nextRouteContext } from '../../contexts/nextRouteContext';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { AnimatePresence, motion } from "framer-motion"
import { isMobileContext } from '../../contexts/isMobileContext';
import ArrowsSpliting from '../Animations/ArrowsSpliting';
import FadeIn from '../Animations/FadeIn';
const UpdatesHeader = ({ headline, title, btnText }) => {
  const { setNextRoute } = useContext(nextRouteContext)
  const { isMobile } = useContext(isMobileContext)
  const router = useRouter()
  const { t } = useTranslation('common')
  return (

    <FadeIn customClass='updates-header'>
      {headline &&
        <span className='_eleX'>{headline}</span>
      }
      <div className='main-header'>
        {title &&
          <h3 className='light _eleX'>{title}
          </h3>
        }
        {btnText && !isMobile &&
          <div className='see-updates _eleX'
            onClick={() => { setNextRoute(t("navbar.media_center")); router.push('/media-center') }}>
            <div className='circle'>
              {!isMobile &&
                <Rings
                  height="40"
                  width="40"
                  color="#31353d"
                  radius="6"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="rings-loading"
                />
              }
            </div>
            <span className=''>
              <ArrowsSpliting>
                {btnText}
              </ArrowsSpliting>
            </span>
          </div>
        }
      </div>
    </FadeIn>
  )
}

export default UpdatesHeader

