import Image from 'next/image'
import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL } from '../../utils/FetchData';
import { AnimatePresence, motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { nextRouteContext } from '../../contexts/nextRouteContext';
import Button from '../common/Button';
import { useTranslation } from "next-i18next"

export const UpdateMobileCard = ({ data, index }) => {
    const router = useRouter()
    const [date, setDate] = useState()
    const { setNextRoute } = useContext(nextRouteContext)
    const { t } = useTranslation("common")

    useEffect(() => {
        const newDate = new Date(data?.attributes?.date)
        const month = newDate.toLocaleString(router.locale, { month: 'long' })
        if (router.locale == "ar") {
            setDate(`${data?.attributes?.date?.split("-")[2]} ${month} ${newDate.getFullYear()} . `)
        } else {
            setDate(`${month.slice(0, 3)} ${data?.attributes?.date?.split("-")[2]}, ${newDate.getFullYear()} . `)
        }
    }, [data, router.locale])
    return (
        <div>
            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ delay: .2, duration: .5 }}
                key={data?.attributes.article_tab?.data?.attributes?.name}
                className='swiper-img'>
                <div className="overlay"></div>
                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ delay: .5, duration: .5 }}
                    className='tag bold'>{data?.attributes.article_tab?.data?.attributes?.name}</motion.div>
                <motion.span
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ delay: .5, duration: .5 }}
                    key={data?.attributes?.date}
                    className='date'>{date}{data?.attributes?.minute_read} {t("common.minutes-read")}</motion.span>
                <Image
                    src={BASE_URL + data?.attributes?.home_card_img_mobile?.data?.attributes?.url}
                    alt={data?.attributes?.home_card_img_mobile?.data?.attributes?.name}
                    layout="fill"
                    objectFit="cover"
                />
                <motion.span
                    initial={{ opacity: 0, y: 40 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: 0, duration: .15 }}
                    onClick={() => {
                    }} className='_eleY circle bold'>
                    <Button
                        text={t("common.read_more")}
                        link={`/media-center/${data?.attributes?.slug}`}
                    />
                </motion.span>
            </motion.div>
            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ delay: index < 2 ? .5 : .2, duration: .5 }}
                key={data?.attributes?.title}
                className='slide-data'>
                <p>{data?.attributes?.title}</p>
                {/* <span className='link' onClick={() => { router.push(`/media-center/${data?.attributes?.slug}`), setNextRoute(data?.attributes?.article_tab?.data?.attributes?.name) }}>Read More</span> */}
            </motion.div>
        </div>
    )
}
